<template>
    <div id="newest">
        <div class="navbar">
            <button><i class="iconfont icon-fanhui1"></i>今日上新</button>
        </div>
        <div class="content">
            <div class="cardBox">
                <div class="card" v-for="item in 10" :key="item">
                    <img src="@/image/seckill.jpg"/>
                    <h4>商品名称</h4>
                    <p>￥<span>400</span>+<span>1000</span>良豆</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    }
}
</script>

<style lang="less" src="@/style/kingkong/newest.less">

</style>